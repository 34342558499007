import * as React from 'react';
import { Box, Typography, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-tailwind/react";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: '100%',
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        float: 'right',
        marginTop: '40px',
    }
}));

export default function ResultModal({open, handleClose, modal}) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div>
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modal}
            >
            <Box style={modalStyle} className={classes.paper}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {modal.description}
                </Typography>
                <Button variant="gradient" onClick={handleClose} className={classes.button}>
                    OK
                </Button>
            </Box>
        </Modal>
        </div>
    );
}
