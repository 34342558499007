import './App.css';
import { Register } from './components/register'
import { RegisterRecurring } from './components/registerRecurring'
import { Exit } from './components/exit'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  const params = new URLSearchParams(window.location.search);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Register chatId={params.get("cid")}/>} />
        <Route exact path="/ehailing" element={<RegisterRecurring chatId={params.get("cid")}/>} />
        <Route exact path="/exit" element={<Exit chatId={params.get("cid")}/>} />
      </Routes>
    </Router>
  );
}

export default App;
