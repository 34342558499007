import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export async function visitorEntryRequest(chat_id, visitor) {
  const response = await axios.post(baseURL + "entry/visitor", { 
    chat_id: chat_id,
    details: {
      name: visitor.name.toUpperCase(), 
      phone: visitor.phone.toUpperCase(), 
      vehicle_number: visitor.vehicle_number.toUpperCase(), 
      unit_number: visitor.unit_number.toUpperCase(), 
      duration_days: visitor.duration_days,
    }
  });

  return response;
}

export async function recurringEntryRequest(chat_id, recurring) {
  const response = await axios.post(baseURL + "entry/recurring", { 
    chat_id: chat_id,
    details: {
      name: recurring.name.toUpperCase(),
      phone: recurring.phone.toUpperCase(), 
      vehicle_number: recurring.vehicle_number.toUpperCase(), 
      unit_number: recurring.unit_number.toUpperCase(), 
      type: recurring.type,
    }
  });

  return response;
}
