import * as React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles, unstable_createMuiStrictModeTheme, ThemeProvider} from "@material-ui/core/styles";

// FIXME. To be fixed in future versions of MUI. Not advised for use in Production: https://material-ui.com/customization/theming/
const theme = unstable_createMuiStrictModeTheme();

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function SimpleBackdrop({open}) {
    const classes = useStyles();

    return (
    <ThemeProvider theme={theme}>
        <Backdrop
            className={classes.backdrop}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </ThemeProvider>
    );
}
