import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export async function exitRequest(chat_id, message_id, sheet_title) {
  const response = await axios.post(baseURL + "exit", { 
    chat_id: chat_id,
    message_id: message_id,
    sheet_title: sheet_title,
  });

  return response;
}
