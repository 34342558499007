import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Typography,
} from "@material-tailwind/react";
import { exitRequest } from "../js/exit";
import SimpleBackdrop from './backdrop';
import ResultModal from './resultModal';

export function Exit({chatId}) {
    const [bodyTitle, setBodyTitle] = useState('Click to confirm exit?');
    const [subtitle, setSubtitle] = useState((localStorage.getItem('_vb_vvnum') != null ? "Vehicle number: " + localStorage.getItem('_vb_vvnum').toUpperCase() : ''));
    const [isShowExit, setIsShowExit] = useState(true);
    const [sheetTitle, setSheetTitle] = useState('');
    const [messageId, setMessageId] = useState('');
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modal, setModal] = useState({
        title: '',
        description: '',
    });

    useEffect(() => {
      setMessageId(localStorage.getItem("_vb_mid"))
    }, [messageId]);

    useEffect(() => {
      setSheetTitle(localStorage.getItem("_vb_stitle"))
    }, [sheetTitle]);

    const handleCloseModal = (event) => {
      setOpenModal(false);
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (chatId === null) {
        //setModal({
        //  ...modal,
        //  title: 'Error',
        //  description: 'No Chat Id found in request'
        //});
        //setOpenModal(true);
        //return;
	      chatId = process.env.REACT_APP_CHAT_ID;
      }

      setOpenBackdrop(true);

      await exitRequest(chatId, messageId, sheetTitle)
        .then((response) => {
          if (response.data.data.status === 'Exited') {
            setOpenBackdrop(false);
            setIsShowExit(false);
            setBodyTitle('Exit process success. Thank you');
            setSubtitle('');
          }else {
            setModal({
              ...modal,
              title: 'Error',
              description: 'Exit process failed'
            });
            setOpenModal(true);
            setOpenBackdrop(false);
          }
        }).catch((error) => {
          setModal({
              ...modal,
              title: 'Error',
              description: 'Exit process failed'
          });
          setOpenBackdrop(false);
        });
    }

    return (
      <>
        <img
          src="/img/background.png"
          className="absolute inset-0 z-0 h-full w-full object-cover"
          alt=""
        />
        <SimpleBackdrop open={openBackdrop}></SimpleBackdrop>
        <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
        <div className="container mx-auto p-4">
          <form onSubmit={handleSubmit}>
            <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
              <CardBody className="flex flex-col gap-4">
                <Typography variant="h5">
                  {bodyTitle}
                </Typography>
                <div className="text-lg">
                  {subtitle}
                </div>
              </CardBody>
                {isShowExit &&
                <CardFooter className="pt-4">
                  <Button variant="gradient" fullWidth type="submit">
                    Exit
                  </Button>
                </CardFooter>
                }
            </Card>
          </form>
        </div>
        <div className="container absolute bottom-6 left-2/4 z-10 mx-auto -translate-x-2/4 text-white"> 
        </div>
        <ResultModal open={openModal} handleClose={handleCloseModal} modal={modal}></ResultModal>
      </>
    );
}

export default Exit;
